// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.template {
  position: relative;
  .button-clone {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    background: lightgrey;
    cursor: pointer;
    border-radius: 2px;
    transition: linear 0.3s;

    &:hover{
      background: white;
    } 
    &.active {
      i {
        color: #3699FF ;
      }
    }
  }
}

.box-cheked-show {
  display: none;

  .title {
    display: block;
    line-height: 2;
    padding: 12px;
    margin-bottom: 12px;
    font-weight: bold;
  }

  .option-box {
    .group-name.validate {
      .name {
        color: red;
      }
      input {
        border: 1px solid red;
      }
    }

    .option-list{
      margin: 1rem 0 0 3rem;
    }
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  .option-delete {
    width: fit-content;
  }
}

.btn-custom {
  @include flex-center();
  padding: 4px 12px;
  cursor: pointer;
  border: none;
  border-radius: 8px;

  &:hover {
    background: #F3F6F9;
  }
}

.span-border {
  display: block;
  width: 100%;
  border: 1px solid #F3F6F9;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem; 
  code { 
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
   
    &.sortable {  
      .svg-icon-sort {
        opacity: 0;
      }
  
      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }    
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.ck-content .image-style-side {
  max-width: 50%;
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
.apexcharts-menu-item.exportSVG {
  display: none !important;
}
.jodit-dialog__box .jodit-dialog {
  max-height: 80% !important;
  max-width: 80% !important;
}
.jodit-dialog__box .jodit-dialog__header {
  overflow: initial !important;
}
.jodit-dialog__box .jodit-dialog__content {
  flex:1 !important;
}

.btnRight {
  position: absolute;
  margin-top: 30px;
  margin-left: 170px;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: grey;
  color: white;
  font-size: 16px;
  padding: 5px 5px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.btnRight:hover {
  background-color: red;
}