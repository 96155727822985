.swal2-container {
  display: -webkit-box;
  display: flex;
  position: fixed;
  z-index: 300000 !important;
}
.my-swal {
  z-index: 10000;
}
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.wbt {
  width: 100px;
  margin-right: 10px;
}
.reactEasyCrop_Container{
  z-index: 9;
}
.appBar {
  position: relative,
}
.title {
  margin-left: spacing(2);
  flex: 1;
}
.app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.my_scroll_divs{
  overflow-y: auto;
  max-height: 600px;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}



.table {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 0.125rem;
  margin-bottom: 1rem;
}

.table .table-title {
  display: flex;
  width: 100%;
  padding: 1rem;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.table-content {
  -webkit-box-flex: 1;
  -ms-flex: auto 1;
  flex: auto 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.table-header {
  box-shadow: none;
  border-bottom: 1px solid #ededed;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table-body,
.table-header,
.table-footer {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}

.table-row {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.table-data {
  padding: 0.75rem 1rem;
  flex: 1 0 auto;
}